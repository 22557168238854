.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s; /* Fade in effect */
}

.modalContent {
  background: #fff;
  position: relative;
  width: 80%;
  max-width: 760px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transform: translateY(-20px);
  animation: slideIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

.modalClose {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 14px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.orderOnlineModal {
  padding: 40px 40px 0 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .header {
    margin: 0;
    font-size: 56px;
    font-weight: 500;
    line-height: 50.4px;
  }

  .info {
    font-weight: 400;
    font-size: 18px;
    line-height: 25.2px;
  }
}

.modalSocialLinksSection {
  margin-top: 8px;
  padding: 16px;
  display: flex;
  width: 100%;

  .boxImage {
    cursor: pointer;
    background: white;
    border: none;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    gap: 4px;
    border-radius: 8px;

    img {
      width: 25px;
      height: 24px;
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 21.6px;
    }
  }

  .boxImage:hover {
    background-color: rgba(245, 244, 242, 1);
  }
}


@media (min-width: 600px) and (max-width: 1129px) {

  .orderOnlineModal {
    padding: 20px;
    gap: 10px;

    .header {
      margin: 0;
      font-size: 30px;
      font-weight: 500;
      line-height: 32.4px;
    }
  }

  .modalSocialLinksSection {
    margin-top: 0;
    padding: 0 16px 16px 16px;

    .imageBox {
      span {
        font-size: 16px;
      }
    }
  }
}

@media(max-width: 605px) {

  .socialLinksSection {
    justify-content: unset !important;
    gap: 4px;

    button {
      width: 100%;
      background: #F3F3F3 !important;
      padding: 12px 16px !important;
      justify-content: center !important;
      border-radius: 12px;
    }

    .orderOnlineModal {
      padding: 20px;

      .header {
        margin: 0;
        font-size: 45px;
        font-weight: 500;
        line-height: 40.4px;
      }
    }
  }

  .modalSocialLinksSection {
    margin-top: 0;
    padding: 0 16px 0 16px;
    flex-direction: column;

    .imageBox {
      span {
        font-size: 12px;
      }
    }
  }
}

.socialLinksSection {
  margin-top: 8px;
  display: flex;
  justify-content: end;

  button {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    border: none;
    padding: 8px 11px;
    background-color: #FFFFFF;
  }
}

@media (min-width: 420px) and (max-width: 600px) {

  .orderOnlineModal {
    padding: 20px;
    gap: 15px;

    .header {
      margin: 0;
      font-size: 35px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .modalSocialLinksSection {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: 420px) {

  .orderOnlineModal {
    padding: 20px;
    gap: 4px;

    .header {
      margin: 0;
      font-size: 25px !important;
      line-height: 25px !important;
    }
  }

  .modalSocialLinksSection {
    margin-top: 0;
    padding: 0;
  }

  .modalClose{

    img {
      width: 26px;
    }
  }
}
