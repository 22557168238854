.header {
  padding: 32px 28px;

  .headerContent{
    display: flex;
    justify-content: space-between;

      .logo {
      padding-left: 12px;
      width: 170px;
      height: 40px;
    }
  }
}

@media (max-width: 880px) {
  .header {
    flex-direction: row;
  }
}

@media (max-width: 880px) {
  .header {
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 24px 8px 28px 8px;

    .headerContent {
      display: flex;
      justify-content: space-between;
    }
  }
}
