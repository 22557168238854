.footer {
  display: flex;
  flex-direction: column;
  gap: 158px;

  &__container {
    padding: 32px 28px 120px 28px;
    background-color: #222224;
  }

  &_links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 12px 12px;
  }

  &__hack {
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    gap: 48px;

    &-column {
      width: 264px;

      &>.footer__hack-column {
        margin-top: 18px;
      }

      p {
        color: #999999;
        margin: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.4px;
        text-transform: uppercase;
      }
    }

    &:first-child {
      width: 300px;
    }
  }
}

.logo {
  height: 28px;
}

.socialLinksSection {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 12px;

  button {
    background-color: transparent;
    cursor: pointer;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;

    img {
      filter: invert(82%) sepia(1%) saturate(4380%) hue-rotate(83deg) brightness(116%) contrast(74%);
    }
    
    span {
      color: #B9D9C2;
    }
  }

  .primaryButton {
    button {
        background-color: #B9D9C2;
        color: #222224;

        img {
          filter: invert(12%) sepia(6%) saturate(357%) hue-rotate(201deg) brightness(92%) contrast(95%);
        }

        span {
          color: #222224;
        }
    }
  }

  img{
    padding: 0 2px;
    width: 28px;
    height: 28px;
  }

  button:hover { 
    background-color: rgba(185, 217, 194, .1);
    outline-color:  rgba(185, 217, 194, .1);
  }

  .primaryButton {
    button:hover {
      background-color: #CDECD6;
      outline-color:  #CDECD6;
      outline-width: 1px;
    }
  }
}
.showMobileDevice{
  display: none;
}


@media (max-width: 784px) {

  .footer {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__hack {
      flex-wrap: wrap;
      gap: 60px;
    }
  }

  .socialLinksSection {
    gap: 0;
  
    button {
      img {
        filter: invert(12%) sepia(6%) saturate(357%) hue-rotate(201deg) brightness(92%) contrast(95%);
      }
      
      span {
        color: #151515;
      }
    }
  }

  .footer__container {
    padding: 32px 20px;
  }
}

@media (max-width: 784px) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__container {
      padding: 32px 8px;
    }

    &_links {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0;
    }

    &__hack{
      gap: 30px;
    }
  }

  .socialLinksSection {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    button {
      border-radius: 12px;
      display: flex;
      width: 50%;
      justify-content: center;
      gap: 8px;
      border: none;
      padding: 12px 15px;
      background-color: #F3F3F3;
    }
  }

  .showMobileDevice{
    display: flex;
    width: 100%;
    margin-top: 8px;
    text-align: center;
    button{
      border-radius: 12px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 2.5px;
      border: none;
      padding: 12px 15px;
      background-color: #F3F3F3;

      img {
        width: 22px;
        height: 24px;
        padding: 0 2px;
      }
    }
  }

  .showWebDevice{
    display: none !important;
  }
}

@media (max-width: 500px) {

  .footer {
    &_links {
      flex-direction: column;
    }

    &_contact{
      display: flex;
      flex-wrap: nowrap;

      div{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    &__hack{
      display: flex;
      flex-direction: column;
      gap: 14px;
    }
  }

  .socialLinksSection {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    button {
      border-radius: 12px;
      display: flex;
      width: 50%;
      justify-content: center;
      align-items: center;
      gap: 4.5px;
      border: none;
      padding: 12px 15px;

      span {
        font-size: 16px
      }
    }
  }

  .showMobileDevice{
    button{
      font-size: 16px;
    }
  }
}


@media (max-width: 415px) {
  .socialLinksSection {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    button {
      border-radius: 12px;
      display: flex;
      width: 50%;
      justify-content: center;
      gap: 4.5px;
      border: none;
      padding: 12px 0;

      span{
        font-size: 14px      }
    }
  }
}

@media (max-width: 375px) {

  .showMobileDevice{
    button{
      font-size: 16px;
    }
  }
}