.types {
  background-color: #F3F7FF;
  padding: 0;
  margin-bottom: -1px;

  img {
    margin-bottom:24px;
  }

  &__head {
    font-weight: 400;
    font-size: 32px;
    line-height: 130%;
    margin: 0 0 36px 0;
  }

  &__items {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E7D17E;
    border-right: 1px solid #E7D17E;
    background-color: #F7E5A3;
    width: 25%;
    padding: 24px 40px 40px 28px;
    transition: scale 0.3s ease-in-out;

    p {
      padding-left: 12px;
      font-size: 18px;
      font-weight: 500;
      line-height: 19.8px;

      margin: 0;
    }

    &-block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-two {
        display: flex;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 80px;

      }
    }

  }

  &__items:hover {
    z-index: 2;
    background: white;
    border-color: white;
    -webkit-box-shadow: 0px 24px 88px -8px rgba(66, 85, 122, 0.2);
    -moz-box-shadow: 0px 24px 88px -8px rgba(66, 85, 122, 0.2);
    box-shadow: 0px 24px 88px -8px rgba(66, 85, 122, 0.2);

    scale: 1.04;
    transition: scale 0.1s ease-in-out;
  }
}

.space {
  flex: 1;
}

.img-wrapper {
  width: auto;
}

.img-wrapper img {
  width: 96px;
  height: 96px;
}

@media (max-width: 870px) {
  .types {
    &__items {
      width: 50%;
    }
  }
}

@media (max-width: 560px) {
  .types {
    &__items {
      height: auto;
    }

    img {
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 600px) {
  .types {
    &__items {
      padding: 0 20px 17px 20px;
    }
  }
}

@media (max-width: 500px) {

  .types {
    &__head {
      font-size: 26px;
    }

    &__items {
      &-two {
        p {
          font-size: 18px;
        }
      }

      p {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 420px) {
  .types {

    &__items {
      min-width: 50%;
      padding: 0 20px 17px 20px;
      min-height: 135px;
      row-gap: 0;

      p {
        padding-left: 0;
      }

      p {
        font-size: 16.7411px;
      }
    }

    img {
      width: 96px;
      margin-bottom: 15px
    }

    &__head {
      font-size: 21.4286px;
      margin-bottom: 24.11px;
    }
  }
}