section {
  padding: 40px 28px;
  background-color: #B9D9C2;
  display: flex;
  flex-direction: column;
  gap: 16px;


  .header {
    p {
      margin: 0;
      padding: 16px 12px;
      font-weight: 400;
      font-size: 48px;
      line-height: 48px;
    }
  }

  .boxContainer {
    border-radius: 16px;
    overflow: hidden;
    margin: 0 16px;
  }

  .boxComments {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1px;

    & > div {
      flex-grow: 1;
      background-color: #FFFFFF;
      padding: 24px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20%;

      img {
        width: 20px;
      }

      .boxStars {
        border: none;
        padding: 0;
      }

      .boxAuthor {
        border: none;
        padding: 0;
        display: flex;
        flex-direction: column;

        .author {
          font-weight: 600;
          line-height: 17px;
          font-size: 14px;
        }
      }
    }
  }

}

@media (max-width: 992px) {
  .boxComments {
    & > div {
      width: 40% !important;
    }
  }
}


@media (max-width: 620px) {
  section {
    padding: 32px 0;

    .header {
      p {
        margin: 0;
        padding: 12px 20px;
        font-weight: 400;
        font-size: 33px;
        line-height: 30px;
      }
    }
  }

  .boxComments {
    flex-wrap: nowrap !important;
    overflow: auto;
    scroll-snap-type: x mandatory;

    & > div {
      width: 90% !important;
      // scroll-snap-align: start;
      padding: 20px 24px;
      height: auto;
      flex: 0 0 auto;
      display: inline-block;
      flex-grow: 1;
      text-align: start;
      box-sizing: border-box;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .boxComments::-webkit-scrollbar {
    height: 8px;
  }

  .boxComments::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  .boxComments::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }
}

@media (max-width: 400px) {
  section {

    .header {
      p {
        font-weight: 400;
        font-size: 25px;
        line-height: 25px;
      }
    }
  }

}

