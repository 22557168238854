@font-face {
    font-family: 'Golos Text VF';
    src: url('GolosTextVF-Regular.eot');
    src: url('GolosTextVF-Regular.eot?#iefix') format('embedded-opentype'),
        url('GolosTextVF-Regular.woff2') format('woff2'),
        url('GolosTextVF-Regular.woff') format('woff'),
        url('GolosTextVF-Regular.ttf') format('truetype'),
        url('GolosTextVF-Regular.svg#GolosTextVF-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-DemiBold.eot');
    src: url('GolosText-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('GolosText-DemiBold.woff2') format('woff2'),
        url('GolosText-DemiBold.woff') format('woff'),
        url('GolosText-DemiBold.ttf') format('truetype'),
        url('GolosText-DemiBold.svg#GolosText-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Bold.eot');
    src: url('GolosText-Bold.eot?#iefix') format('embedded-opentype'),
        url('GolosText-Bold.woff2') format('woff2'),
        url('GolosText-Bold.woff') format('woff'),
        url('GolosText-Bold.ttf') format('truetype'),
        url('GolosText-Bold.svg#GolosText-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Black.eot');
    src: url('GolosText-Black.eot?#iefix') format('embedded-opentype'),
        url('GolosText-Black.woff2') format('woff2'),
        url('GolosText-Black.woff') format('woff'),
        url('GolosText-Black.ttf') format('truetype'),
        url('GolosText-Black.svg#GolosText-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Medium.eot');
    src: url('GolosText-Medium.eot?#iefix') format('embedded-opentype'),
        url('GolosText-Medium.woff2') format('woff2'),
        url('GolosText-Medium.woff') format('woff'),
        url('GolosText-Medium.ttf') format('truetype'),
        url('GolosText-Medium.svg#GolosText-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Regular.eot');
    src: url('GolosText-Regular.eot?#iefix') format('embedded-opentype'),
        url('GolosText-Regular.woff2') format('woff2'),
        url('GolosText-Regular.woff') format('woff'),
        url('GolosText-Regular.ttf') format('truetype'),
        url('GolosText-Regular.svg#GolosText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

