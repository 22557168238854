@import "./assets/fonts/golosFont.css";

:root{
  --base-color: #151515
}

* {
  box-sizing: border-box;
  color: var(--base-color);
}

.main {
  max-width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  font-family: "Golos Text", sans-serif;
}

html, body {
  overscroll-behavior-x: none;
  overflow-x: hidden;
  height: 100%;
}