body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline-width: 8px;
  outline-style: solid;
  outline-color: transparent;
  transition: outline-width 0.3s ease-in-out, outline-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

button:hover {
  outline-width: 0;
  outline-style: solid;
  outline-color: rgba(245, 244, 242, 1);
  transition: outline-width 0.1s ease-in-out, outline-color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}