.sectionAboutUs {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 40px;
  padding: 65px 28px;

  .header {
    padding: 0 12px;
    flex-grow: 4;


    .title {
      margin: 0;
      font-weight: 400;
      font-size: clamp(44px, 10vw, 72px);
      line-height: 65px;
    }
  }

  .phoneSection {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .phoneNumber {
      padding: 0 8px;

      p {
        margin: 0;
        font-size: 48px;
        line-height: 48px;
      }
    }

    .groupCallCentre {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .socialLinksSection {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .groupCallBtn {
          display: flex;
          justify-content: end;
          flex-wrap: nowrap;
          gap: 8px;

          button {
            cursor: pointer;
            min-width: 122px;
            padding: 8px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 8px;
            font-weight: 400;
            gap: 2.5px;
            font-size: 18px;
            line-height: 19.2px;
            background-color: white;
          }

          img{
            padding: 0 2px;
            width: 28px;
            height: 28px;
          }

          button:hover{
            background-color: rgba(245, 244, 242, 1);
          }
        }
      }


      .orderBtnSection {
        padding: 0 12px;
        display: flex;
        justify-content: end;

        button {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          padding: 12px 16px;
          border: none;
          border-radius: 12px;
          background-color: #151515;
          outline-width: 0;
          outline-color: transparent;
          transition: outline-width 0.3s ease-in-out, outline-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

          span {
            color: rgba(255, 255, 255, 1);
            font-weight: 500;
            font-size: 19px;
            line-height: 22.8px;
          }
        }

        button:hover {
          outline-width: 2px;
          outline-style: solid;
          outline-color: #2A2A2A;
          background-color: #2A2A2A;
          transition: outline-width 0.1s ease-in-out, outline-color 0.1s ease-in-out, background-color 0.1s ease-in-out;
        }
      }
    }
  }
}


@media (max-width: 1150px) {
  .sectionAboutUs {
    flex-wrap: nowrap;

    .header {
      padding: 0 8px;

      .title {
        font-size: 44px;
        line-height: 51px;
      }
    }

    .phoneSection {
      width: 40%;

      .phoneNumber {
        padding: 0px 8px;

        p {
          text-align: end;
          font-size: 32px;
          line-height: 32px;
        }
      }

      .groupCallCentre {
        gap: 8px;
      }

      .orderBtnSection {
        padding: 0 !important;
      }
    }
  }
}

@media (max-width: 800px) {
  .sectionAboutUs {
    padding: 32px 12px;
    flex-wrap: wrap;
    gap: 5px;

    .header {
      padding: 0 8px;

      .title {
        font-size: 35px;
        line-height: 48.6px;
      }
    }

    .phoneSection {
      width: 40%;
      gap: 5px;

      .phoneNumber {
        padding: 0;

        p {
          text-align: end;
          margin: 0;
          font-size: 26px;
          line-height: 32px;
        }
      }

      .groupCallCentre {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0;
      }

      .orderBtnSection {
        button {
          padding: 10.5px 14px;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .sectionAboutUs {
    padding: 32px 12px;
    gap: 48px;

    .header {
      padding: 0 8px;

      .title {
        margin: 0;
        font-size: 44px;
        line-height: 39.6px;
      }
    }

    .phoneSection {
      width: 100%;
      gap: 12px;

      .phoneNumber {
        padding: 16px 8px;

        p {
          text-align: start;
          font-size: 32px;
          line-height: 32px;
        }
      }

      .groupCallCentre {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 8px;

        .socialLinksSection{
          .groupCallBtn{
            button{
              width: 50%;
              background-color: rgba(245, 244, 242, 1);
              gap: 7px;

              img{
                padding: 2.5px;
              }
            }
          }
        }
      }

      .orderBtnSection {
        padding: 0 !important;

        button {
          width: 100%;
        }
      }
    }
  }
}


@media (max-width: 427px) {
  .sectionAboutUs {
    .header {
      .title {
        font-size: 40px;
        line-height: 39.6px;
      }
    }
  }
}


@media (max-width: 391px) {
  .sectionAboutUs {
    .header {
      .title {
        font-size: 35.6px;
        line-height: 39.6px;
      }
    }
  }
}

@media (max-width: 360px) {
  .sectionAboutUs {
    .header {
      .title {
        font-size: 33px;
        line-height: 35px;
      }
    }
  }
}