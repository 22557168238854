.advantages {
  background-color: #B9D9C2;
  padding: 48px;

  &__desc {
    padding: 48px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .box {
      display: flex;
      flex-direction: column;
      padding: 24px 48px;

      div {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        p {
          margin: 0 0 0 12px;
          font-size: 56px;
          line-height: 56px;
        }
      }

      span {
        padding-top: 16px;
        font-size: 18px;
      }

    }
  }

  &__benefits {
    padding: 56px 0 24px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 66px;
    border-top: 1px solid #98C8A6;

    &-column {
      margin: 7px 0px 7px 1px;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 16px;
      line-height: 19.8px;

      p {
        margin: 4px 0px 4px 1px;
        display: flex;
        align-items: center;
      }

      svg {
        width: 22px;
        margin-right: 11px;
      }
    }
  }
}

@media (max-width: 1056px) {
  .advantages {


    &__desc {
      padding: 24px 0;
    }
  }
}

@media (max-width: 960px) {
  .advantages {
    &__desc {
      justify-content: center;
    }
  }

  .advantages__benefits {
    gap: 0;
  }
}

@media (max-width: 768px) {
  .advantages {
    padding: 32px 20px;

    .advantages__benefits {
      &-column {
        margin: 0;
      }
    }

    &__benefits {
      padding: 0;
    }

    &__desc {
      line-height: 131%;
    }

    &__benefits {
      flex-direction: column;
    }
  }
}

@media (max-width: 500px) {
  .advantages {

    &__benefits {
      padding: 0;
    }

    &__desc {
      gap: 16px;
      padding: 0;

      .box {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        width: 100%;
        align-items: center;

        div {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;

          p {
            margin: 0 0 0 12px;
            font-size: 44px;
            line-height: 44px;
          }
        }

        span {
          text-align: center;
          padding-top: 16px;
        }

      }
    }

  }

  .advantages__benefits {
    padding-top: 43px;

    &-column {
      margin: 0;
      font-weight: 400;
      font-size: 16px;

      p {
        margin: 7px 0px 7px 1px;
        display: flex;
        align-items: center;
      }

      svg {
        width: 22px;
      }
    }
  }

  .advantages__desc {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .advantages {
    padding: 32px 20px;

    &__desc {
      margin: 0 0 22px 0;
      font-size: 16px;
    }
  }

  .advantages__benefits {
    &-column {
      font-size: 14.0625px;

      p {
        margin: 1.7px 0 1px 1px;
      }

      svg {
        width: 22px;
        margin-right: 9px;
      }
    }
  }
}