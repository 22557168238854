.languageSwitcher {
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  button{
    border: none;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 12px 12.5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
  }

  button:hover{
    background: rgba(245, 244, 242, 1) !important;
  }
}

.languageIcon {
  border: 1px solid #e6e3de;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}


