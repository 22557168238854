.garage {
  background-color: #A6CDDC;
  background-image: url(../../assets/images/garage/cell.svg);
  display: flex;
  flex-direction: column;
  padding: 48px 28px;
  justify-content: space-between;

  .boxDesc{
    .desc {
      padding: 16px 12px;
      font-size: 48px;
      font-weight: 400;
      line-height: 48px;
      margin: 0;
    }
  }

  .imgWrapper {
    max-height: 100%;
    text-align: center;

    img {
      max-width: 978px;
      height: 448px;
    }
  }

  .optionsSection {
    padding: 24px 12px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: #222224;
      padding: 8px 12px;
      border-radius: 12px;

      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 0;

        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 21.6px;
          color: #A6CDDC;
        }

        p {
          margin: 0;
          font-size: 32px;
          line-height: 32px;
          color: #A6CDDC;
        }
      }

      .xIcon {
        justify-content: center;
        padding: 0 24px;

        img {
          margin: 0;
          max-width: 28px;
          max-height: 32px;
          align-items: center;
        }
      }

      .showMobileDevice {
        display: none;
      }
    }
  }

  &-car {
    position: absolute;
    top: -64px;
    right: 0;
  }
}


@media (max-width: 1015px) {
  .garage {
    flex-direction: column;

    .imgWrapper {
      width: 100%;
      text-align: center;

      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    .optionsSection {
      padding: 24px 12px 24px 12px;

      div {
        gap: 32px;
        padding: 16px 4px;

        .xIcon {
          display: none;
        }
      }

      .showMobileDevice {
        display: block !important;
      }

      .showWebDevice {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .garage {
    padding: 0;
    gap: 8px;

    .boxDesc{
      padding: 32px 8px 0  8px;
      .desc {
        padding:  0 12px;
        max-width: 100%;
        font-size: 32px;
        line-height: 29px;
      }
    }

    &-car {
      position: unset;
      display: block;
    }

    .optionsSection {
      padding: 0 8px 32px 8px;

      .boxOption{
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        div {
          padding: 0 12px;
          flex-grow: 1;
          gap: 4px;

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 17.6px;
          }

          p {
            margin: 0;
            font-size: 32px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

@media (max-width: 384px) {
  .garage {
    .optionsSection {

      .boxOption{
        display: flex;
        flex-wrap: wrap;
        gap: 16px;


        div {
          width: 120px;
          padding: 0 12px;
          flex-grow: 2;
          gap: 4px;


          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 17.7px;
          }

          p {
            margin: 0;
            font-size: 29px;
            line-height: 29px;
          }
        }
      }
    }
  }
}