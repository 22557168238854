.locations {
  position: relative;
  padding: 0;
  aspect-ratio: 16 / 9;

  .mapContainer {
    position: relative;
    height: 100%;

    .map-dim-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: rgba(0, 0, 0, 0.3); /* 50% opacity, adjust as needed */
      z-index: 1; /* Ensure it is below the markers */
      pointer-events: none; /* Allow interactions with the map */
    }

    .marker {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #CF0000;
      outline: 2px solid white;
      z-index: 3;
    }

    .pulse-marker {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: white;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: -100%;
        left: -100%;
        transform: translate(-50%, -50%); /* Center the pulse */
        width: 24px; /* Initial size of the pulse */
        height: 24px;
        border-radius: 50%;
        background-color:white; /* Transparent pulse */
        opacity: 0;
        z-index: 0; /* Ensure it is behind the marker */
        animation: pulse 2s infinite; /* Pulse animation */
        animation-delay: var(--animation-delay, 0s); /* Use a CSS variable for delay */
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(0);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
  }

  p {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 4;
    margin: 0;
    font-weight: 400;
    font-size: 56px;
    line-height: 50.4px;
    text-align: start;
    color: #FFFFFF;
    max-width: 720px;
  }

  @media (max-width: 768px) {
    .locations {
      aspect-ratio: 1 / 1;
    }

    p {
      font-size: 45px;
      top: 30px;
      left: 20px;
      max-width: 80%;
      line-height: 40px;
    }

  }

  @media (max-width: 500px) {
    .locations {
      aspect-ratio: 1 / 1;
    }

    p {
      font-size: 32px;
      line-height: 28.8px;
      top: 24px;
      left: 20px;
      max-width: 90%;
    }
  }
}